<template>
  <div class="search_topic_warp">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <div class="post-top" v-for="tag in list" :key="tag.id" @click="goTag(tag)">
        <div class="post-top-left flex-center-center">
          <div class="post-avatar">
            <ImgDecypt :src="tag.coverImg" />
          </div>
          <div class="post-info">
            <div class="username_box ellipsis">
              <span>#{{ tag.name }}</span>
            </div>
            <!--显示粉丝-->
            <div class="fan">
              <div class="fan_text">话题总播放次数 {{ tag.playCount | watchCount }}</div>
            </div>
          </div>
        </div>
        <!-- <div class="post-top-right" @click.stop="collect(tag)" v-if="!tag.hasCollected">
          <svg-icon iconClass="focusOn"></svg-icon>
        </div> -->
      </div>
    </PullRefresh>
  </div>
</template>
<script>
import { searchTagorUser } from '@/api/community';
import PullRefresh from '@/components/PullRefresh';
import ImgDecypt from '@/components/ImgDecypt';
import { careCollectVideo } from '@/api/user';
import { Toast } from 'vant';

export default {
  name: 'searchTopic',
  props: {
    keyword: {
      type: String,
      required: true,
      default: '',
    },
  },
  components: {
    PullRefresh,
    ImgDecypt,
  },
  data() {
    return {
      params: {
        pageNumber: 1,
        pageSize: 15,
      },
      list: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList(type) {
      let req = {
        keyWords: [this.keyword],
        realm: 'tag',
        pageNumber: this.params.pageNumber,
        pageSize: this.params.pageSize,
      };
      try {
        let res = await this.$Api(searchTagorUser, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.params.pageNumber == 1 && this.list.length == 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (e) {
        this.loading = false;
        this.error = true;
      }
    },
    goTag(item) {
      this.$router.push({ path: '/tags', query: { id: item.id } });
    },
    async collect(tag) {
      let isCollect = tag.hasCollected;
      let req = {
        isCollect: !isCollect,
        objIds: [tag.id],
        type: 'tag',
      };
      let res = await this.$Api(careCollectVideo, req);
      if (res.code === 200) {
        tag.hasCollected = !isCollect;
        if (isCollect) {
          Toast('取消关注');
        } else {
          Toast('关注成功');
        }
        return;
      }
      Toast(res.tip || '操作失败');
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.params.pageNumber++;
      }
      this.getList();
    },
    onRefresh() {
      this.params.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
  },
};
</script>
<style lang="scss" scoped>
.search_topic_warp {
  padding: 8px 0;

  .post-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px;
    background-color: #ffffff;
    margin-bottom: 4px;

    .post-top-left {
      .post-avatar {
        width: 64px;
        height: 64px;
        border-radius: 11px;
        margin-right: 8px;
        overflow: hidden;
      }

      .post-info {
        width: 200px;
        display: flex;
        flex-direction: column;

        .username_box {
          span {
            font-size: 17px;
            letter-spacing: -0.41px;
            color: #333333;
          }
        }

        .fan {
          font-size: 13px;
          padding-top: 13px;

          .fan_text {
            color: #999999;
          }
        }
      }
    }

    .post-top-right {
      svg {
        width: 70px;
        height: 25px;
      }
    }
  }
}
</style>
